<template>
  <div class="rolePageBox">
    <!-- 上面选择的呀 -->
    <div class="choosePlatform">
      <span>授权系统</span>
      <el-select v-model="platform" placeholder="请选择" @change="getRole">
        <el-option
          v-for="item in platformArr"
          :key="item.dictId"
          :label="item.dictName"
          :value="item.dictId"
        />
      </el-select>
    </div>
    <!-- 下面菜单之类的东西 -->
    <div class="contentBox">
      <!-- 左边 -->
      <div class="left">
        <h3 class="title">
          角色与权限
        </h3>
        <ul class="roleList">
          <!-- 角色列表 -->
          <li
            v-for="(item, index) in listData"
            :key="index"
            :class="activeIndex === index ? 'active' : ''"
          >
            <div class="role elp-1" @click="getActiveIndex(index, item)">
              <i class="el-icon-s-custom" />
              <span>{{ item.roleName }}</span>
            </div>
            <!-- 按钮 -->
            <i
              v-if="item.createUser === 'sys' || item.isAdmin === '1'"
              class="icon el-icon-more"
            />
            <el-dropdown v-else>
              <i class="icon el-icon-more" />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="rechristens(item)">
                  重命名
                </el-dropdown-item>
                <el-dropdown-item @click.native="deleteRow(item, index)">
                  删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
        <!-- 新增按钮 -->
        <div class="newButton">
          <span @click="addRoleFu">新增角色</span>
        </div>
      </div>
      <!-- 右边 -->
      <div class="right">
        <h3 class="title">
          {{ current.roleName }}
          <el-button
            v-if="isEdit"
            :disabled="current.isAdmin === '1' || !current.roleCode"
            class="button"
            @click="isEdit = false"
          >
            编辑
          </el-button>
          <el-button v-else class="button" @click="determineEdit">
            保存
          </el-button>
        </h3>
        <p class="describe">
          {{ current.remark }}
        </p>
        <!-- 头部 -->
        <div class="tableHeader">
          <!-- 复选框 -->
          <div class="checkBox">
            <el-checkbox
              v-model="checkAllRole"
              :disabled="isEdit"
              @change="handleCheckAllChange"
            >
              所有权限
            </el-checkbox>
          </div>
          <!-- 操作对象 -->
          <p class="operand">
            操作对象
          </p>
          <p class="accessPoint">
            权限点
          </p>
        </div>
        <!-- 权限点盒子 -->
        <div class="accessPointBoxPage">
          <div
            v-for="(item, index) in accessPointArr"
            :key="index"
            class="accessPointBox"
          >
            <!-- 父权限 -->
            <div class="parentPermission">
              <div class="checkBox">
                <el-checkbox
                  v-model="item.show"
                  :disabled="isEdit"
                  @change="handleCheckAllChange1($event, item, accessPointArr)"
                >
                  全选
                </el-checkbox>
              </div>
              <div class="permissionName">
                <i
                  v-if="
                    item.children &&
                      item.children.length > 0 &&
                      item.children[0].resOperType != 3
                  "
                  :class="
                    item.isShow
                      ? 'active el-icon-caret-bottom'
                      : 'el-icon-caret-bottom'
                  "
                  @click="packUp(item)"
                />
                <i v-else class="icon" />
                <span>{{ item.resName }}</span>
              </div>
              <div
                v-if="
                  item.children &&
                    item.children.length > 0 &&
                    item.children[0].resOperType == 3
                "
                class="accessPointNode"
              >
                <el-checkbox
                  v-for="(val, num) in item.children"
                  :key="num"
                  v-model="val.show"
                  :disabled="isEdit"
                >
                  {{ val.resName }}
                </el-checkbox>
              </div>
            </div>
            <!-- 子权限 -->
            <div
              v-if="
                !item.isShow &&
                  item.children &&
                  item.children.length > 0 &&
                  item.children[0].resOperType != 3
              "
            >
              <div
                v-for="(lable, inde) in item.children"
                :key="inde"
                class="childRights"
              >
                <div class="checkBox">
                  <el-checkbox
                    v-model="lable.show"
                    :disabled="isEdit"
                    @change="handleCheckAllChange2($event, lable, item)"
                  >
                    全选
                  </el-checkbox>
                </div>
                <span class="nodeName">{{ lable.resName }}</span>
                <div
                  v-if="lable.children && lable.children.length > 0"
                  class="accessPointNode"
                >
                  <!-- <el-checkbox :disabled="isEdit" v-model="checkAll" @change="handleCheckAllChange">
                  查看
                </el-checkbox> -->
                  <el-checkbox
                    v-for="(val, num) in lable.children"
                    :key="num"
                    v-model="val.show"
                    :disabled="isEdit"
                  >
                    {{ val.resName }}
                  </el-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增弹窗 -->
    <!-- 弹出的新增跟修改框 -->
    <Dialog
      ref="dialog"
      dialog-width="32%"
      :add-and-edit-form-item="addAndEditFormItem"
      :edit-form-data="editForm"
      @getFormData="getFormData"
    />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog'
import { translateDataToTree } from '@/unit/index'
import {
  addRole,
  deleteRole,
  updateRole,
  saveRolePermission,
  getRoleOfResource,
  getAllRoles,
  baseinfoCmpRole,
  getResourceBySysSelect
} from '@/http/custApi/institutionalFramework'
export default {
  components: {
    Dialog
  },
  data() {
    return {
      platform: '',
      isIndeterminate: true,
      checkAllRole: false,
      accessPointArr: [],
      listData: [],
      isEdit: true,
      activeIndex: null,
      editForm: {},
      current: {
        roleCode: '',
        roleName: '角色'
      },
      addAndEditFormItem: [
        {
          label: '角色名称',
          type: 'input',
          value: 'roleName',
          rules: [
            { required: true, message: '请输入角色名称', trigger: 'blur' },
            {
              min: 1,
              max: 20,
              message: '长度在 1 到 20 个字符',
              trigger: 'blur'
            }
          ]
        },
        { label: '备注', type: 'input', value: 'remark', rules: 'none' }
      ],
      roleCode: '',
      checkedRoleArr: [],
      submitDataArr: [],
      platformArr: []
    }
  },
  watch: {
    // 监测已选中的功能点
    checkedRoleArr: {
      handler(newVal) {
        this.assignment(this.accessPointArr, newVal)
      },
      immediate: true,
      deep: true
    },
    // 监测左边的数组
    accessPointArr: {
      handler(newVal) {
        if (newVal && newVal.length === 0) return
        let flag = true
        newVal.forEach((item) => {
          if (!item.show) flag = false
          this.checkParentNode(item)
        })
        this.checkAllRole = flag
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // 获取该平台下所拥有角色的平台
    baseinfoCmpRole((res) => {
      const arr = this.$store.getters.getDictionaryItem('SYS_TYPE') || []
      const data = [...res.data] || []
      this.platformArr = arr.filter((item) => data.includes(item.dictId))
      // 给平台赋值之后就选择第一个为当前渲染
      if (this.platformArr.length > 0) {
        this.getRole(this.platformArr[0].dictId)
        this.getRoleList(this.platformArr[0].dictId)
        this.platform = this.platformArr[0].dictId
      }
    })
  },
  methods: {
    addRoleFu() {
      this.$refs.dialog.editFormVisible = true
      this.editForm = {}
    },
    // 获取新增数据进行添加
    getFormData(value) {
      addRole({ ...value, cmpRole: this.platform }, () => {
        this.$message.success('新增成功！')
        this.getRoleList(this.platform)
        this.$refs.dialog.editFormVisible = false
      })
    },
    // 删除函数
    deleteRow(row, index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteRole(row.roleCode, () => {
            this.listData.splice(index, 1)
            this.$message.success('删除成功！')
          })
        })
        .catch()
    },
    // 重命名
    rechristens(row) {
      this.$prompt('角色名称', '重命名', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: (value) => {
          if (!value) {
            return '请输入'
          }
        }
      })
        .then(({ value }) => {
          updateRole(
            { ...row, cmpRole: row.usePlatform, roleName: value },
            () => {
              this.$set(row, 'roleName', value)
              this.$message.success('重命名成功！')
            }
          )
        })
        .catch()
    },
    // 保存权限
    determineEdit() {
      this.submitDataArr = []
      if (!this.current.roleCode) {
        this.$message.error('请选择角色')
        return
      }
      if (this.current.isAdmin === '1') {
        this.$message.error('超级管理员角色不能修改')
        return
      }
      if (this.current.createUser === 'sys') {
        this.$message.error('运营平台创建的角色不能修改')
        return
      }
      this.getSumbitData(this.accessPointArr)
      saveRolePermission(
        {
          params: {
            resourceList: [...this.submitDataArr] || [],
            roleCode: this.current.roleCode
          }
        },
        (res) => {
          this.menuAccessshow = false
          this.isEdit = true
          this.$message.success('保存成功！')
        },
        () => {
          this.isEdit = true
        }
      )
    },
    // 那取要提交的数据
    getSumbitData(arr) {
      if (!arr || arr.length === 0) return
      arr.forEach((item) => {
        // 如果是选择了的就赋值
        if (item.show) this.submitDataArr.push(item.resCode)
        if (item.children && item.children.length > 0) {
          this.getSumbitData(item.children)
          // 判断子元素有一个选择的就勾选上
          if (!item.show) {
            let flag = false
            item.children.forEach((val) => {
              if (val.show) flag = true
            })
            if (flag) this.submitDataArr.push(item.resCode)
          }
        }
      })
    },
    // 递归使父节点也勾选
    checkParentNode(obj) {
      if (!obj || !obj.children || obj.children.length === 0) return
      let flag = true
      obj.children.forEach((item) => {
        if (!item.show) flag = false
        if (item.children && item.children.length > 0) {
          this.checkParentNode(item)
        }
      })
      this.$set(obj, 'show', flag)
    },
    // 递归获取已选的值
    assignment(arr, checkArr) {
      if (!arr || arr.length === 0) return
      arr.forEach((item) => {
        if (item.children && item.children.length > 0) {
          this.assignment(item.children, checkArr)
        }
        if (checkArr.includes(item.resCode)) {
          this.$set(item, 'show', true)
          return
        }
        this.$set(item, 'show', false)
      })
    },
    // 获取角色所拥有的权限
    getRoleHave(code) {
      getRoleOfResource(code, (res) => {
        this.checkedRoleArr = [...res.data]
      })
    },

    // 点击右边那个li
    getActiveIndex(index, item) {
      this.activeIndex = index
      this.current = { ...item }
      this.isEdit = true
      this.getRoleHave(item.roleCode)
    },
    // 获取系统下的角色
    getRoleList(code) {
      getAllRoles(code, (res) => {
        this.listData = [...res.data]
      })
    },
    // 头部的全选
    handleCheckAllChange(type) {
      this.judgeSelection(type, this.accessPointArr)
    },
    // 点击一级菜单的全选的时候
    handleCheckAllChange1(type, obj) {
      if (obj.children && obj.children.length > 0) { this.judgeSelection(type, obj.children) }
    },
    // 点击二级菜单的全选的时候
    handleCheckAllChange2(type, obj) {
      if (obj.children && obj.children.length > 0) { this.judgeSelection(type, obj.children) }
    },
    // 点击收起按钮
    packUp(item) {
      const type = !item.isShow
      this.$set(item, 'isShow', type)
    },
    getRole(roleType) {
      this.getRoleList(roleType)
      getResourceBySysSelect(roleType, (res) => {
        res.data.forEach((item) => {
          this.$set(item, 'show', false)
        })
        const data = translateDataToTree(res.data, 'resPcode', 'resCode')
        if (data.length > 0) this.accessPointArr = [...data[0].children]
      })
    },
    // 根据值判断他的子元素是全选还是全部不选
    judgeSelection(type, arr) {
      if (!arr || arr.length === 0) return
      arr.forEach((item) => {
        this.$set(item, 'show', type)
        if (item.children && item.children.length > 0) {
          this.judgeSelection(type, item.children)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.rolePageBox {
  background-color: #fff;

  .contentBox {
    margin-top: 22px;
    display: flex;
    padding: 0 30px 0 19px;

    .newButton {
      margin: 32px 0;
      text-align: center;

      span {
        display: inline-block;
        cursor: pointer;
        width: 160px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        background-color: #0089ff;
        border-radius: 4px;
      }
    }

    .right {
      flex: 1;

      .accessPointBoxPage {
        height: 100%;
        overflow: auto;
      }

      .accessPointBox {
        padding: 30px 0 33px;
        border-bottom: 1px solid #ebebeb;

        .childRights {
          display: flex;
          align-items: center;
          margin-top: 16px;
          color: #333;
          font-size: 12px;

          .accessPointNode {
            flex: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .el-checkbox {
              margin-right: 12px;
            }
          }

          .nodeName {
            margin-left: 18px;
            width: 170px;
          }

          .checkBox {
            width: 188px;
            margin-left: 22px;
          }
        }

        .parentPermission {
          display: flex;
          align-items: center;

          .permissionName {
            width: 180px;
            font-size: 12px;
            cursor: pointer;

            i {
              color: #c0c4cc;
              margin-right: 6px;
              transition: all 0.5s;
            }

            .active {
              color: $pointClearColor;
              transform: rotate(180deg);
            }

            .icon {
              margin-right: 6px;
              width: 12px;
              height: 1px;
              display: inline-block;
            }
          }

          .checkBox {
            width: 188px;
            margin-left: 22px;
          }
        }
      }

      .tableHeader {
        height: 42px;
        background-color: #f9f9f9;
        border: 1px solid #e3e3e3;
        color: #333;
        font-size: 12px;
        display: flex;
        align-items: center;

        .accessPoint {
          flex: 1;
        }

        .operand {
          width: 188px;
        }

        .checkBox {
          margin-left: 22px;
          width: 188px;
        }
      }

      .describe {
        padding: 11px 0;
        color: #a4a4a4;
        height: 14px;
        font-size: 14px;
      }

      .title {
        margin-top: 12px;
        font-size: 14px;
        height: 12px;
        line-height: 12px;
        color: $mainFontColor;
        padding-left: 4px;
        border-left: 2px solid $mainBlue;

        .button {
          float: right;
          display: block;
          cursor: pointer;
          width: 160px;
          height: 40px;
          text-align: center;
          font-size: 14px;
          color: #fff;
          background-color: #0089ff;
          border-radius: 4px;
        }
        .el-button.is-disabled {
          color: #c0c4cc;
          cursor: not-allowed;
          background-image: none;
          background-color: #fff;
          border-color: #ebeef5;
        }
      }
    }

    .left {
      width: 182px;
      padding: 12px 18px;
      margin-right: 30px;
      background-color: #fbfbfb;
      border: 1px solid $mainBorderColor;

      .roleList {
        margin-top: 17px;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 0;
          cursor: pointer;

          .role {
            width: calc(100% - 16px);

            span {
              color: #6a6c70;
              font-size: 14px;
              margin-left: 4px;
            }

            i {
              color: #c0c4cc;
            }
          }

          .icon {
            height: 14px;
            width: 14px;
            text-align: center;
            line-height: 14px;
            display: inline-block;
            border-radius: 50%;
            cursor: pointer;
            border: 1px solid #c0c4cc;
            font-size: 12px;
            color: #c0c4cc;
          }
        }

        .active {
          background-color: #f4f8fe;
          color: #0089ff !important;

          .role {
            span,
            i {
              color: #0089ff;
            }
          }

          .icon {
            color: #0089ff;
            border: 1px solid #0089ff;
          }
        }
      }

      .title {
        font-size: 14px;
        height: 12px;
        line-height: 12px;
        color: $mainFontColor;
        padding-left: 4px;
        border-left: 2px solid $mainBlue;
      }
    }
  }

  .choosePlatform {
    display: flex;
    align-items: center;
    padding: 20px 0 0 20px;
    font-size: 14px;
    color: $mainFontColor;

    .el-select {
      width: 280px;
      height: 40px;
    }

    span {
      padding-right: 18px;
    }
  }
}
</style>
